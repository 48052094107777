@import "fsx-palette";
@import "fsx-theme";
@import "colors";
@import "buttons";
@import "labels";
@import "text";
@import "dropdowns";
@import "inputs";
@import "tables";
@import "custom-material";
@import "checkbox";

// Global properties for consistency sake
$side-nav-width: 60px;

.prevent-select {
  -webkit-user-select: none; /* Safari */
  -ms-user-select: none; /* IE 10 and IE 11 */
  user-select: none; /* Standard syntax */
}

.hover-icons {
  margin-left: 10px;
  cursor: pointer;

  span {
    margin-left: 20px;
  }

  span,
  i {
    &:hover {
      color: $ui-primary;
    }
  }
}

.fsx-tooltip {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 10px !important;
  height: 10px;
  display: flex;
  align-items: center;
}

.pdf-viewer-dialog {
  .mat-dialog-container {
    border-radius: 0 !important;
    padding: 0 !important;
    overflow-y: hidden;
  }
}

.confirmation-dialog-delete {
  .mat-dialog-container {
    position: absolute !important;
    top: 100px !important;
    height: fit-content !important;
    width: 373px !important;
    padding: 20px !important;
    box-shadow: 0 1px 0 0 #eaeaea;
    background-color: #fff;
    border-radius: 0 !important;
  }
}

.filing-submission-receipt-dialog {
  .mat-dialog-container {
    padding: 0;
  }
}

.transactions-container {
  .mat-form-field-wrapper {
    padding-bottom: 20px !important;
  }
}

::-webkit-scrollbar-corner {
  background: rgba(0, 0, 0, 0.5);
}

* {
  scrollbar-width: thin;
  scrollbar-color: $light-gray $light-gray;
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: $light-gray;
}

*::-webkit-scrollbar-thumb {
  background-color: $border-gray;
  border-radius: 20px;
  border: 3px solid $light-gray;
}

.checkbox {
  width: 15px;
  cursor: pointer;
  margin: 0 10px 0 0;
}
