::ng-deep mat-checkbox {
  .mat-ripple-element {
    display: none !important;
  }

  .mat-checkbox-inner-container {
    height: 16px;
    width: 16px;
  }

  .mat-checkbox-background,
  .mat-checkbox-frame {
    border-radius: 0 !important;
    border-color: $cloudy-blue !important;
    border-width: 1px !important;
  }

  &.mat-checkbox-checked .mat-checkbox-background {
    background-color: $ui-primary !important;
  }

  &:hover .mat-checkbox-frame {
    border-color: $ui-primary !important;
  }

  &.mat-checkbox-checked.mat-checkbox-disabled .mat-checkbox-background {
    border: 1px solid $cloudy-blue !important;
    background-color: $cloudy-blue !important;

    .mat-checkbox-checkmark-path {
      stroke: $lighter-gray !important;
    }
  }

  &.mat-checkbox-disabled:not(.mat-checkbox-checked) .mat-checkbox-background {
    background-color: $cloudy-blue !important;
    border: 1px solid $cloudy-blue !important;

    svg {
      display: flex;
      background: $lighter-gray;
      height: 2px;
      width: 6px;
      margin-top: 4px;
      margin-left: 2px;
    }
  }

  &.mat-checkbox-disabled.mat-checkbox-checked.readonly {
    .mat-checkbox-background {
      background-color: transparent !important;
      border: none !important;
    }

    .mat-checkbox-frame {
      border: none !important;
    }
  }

  &.mat-checkbox-disabled:not(.mat-checkbox-checked).readonly {
    .mat-checkbox-frame {
      border: none !important;
    }

    .mat-checkbox-background {
      background-color: transparent !important;
      border: none !important;

      svg {
        display: flex;
        background: $cloudy-blue;
        height: 6px;
        width: 6px;
        margin-top: 2px;
        margin-left: 3px;
        border: 1px solid $lighter-gray;
      }
    }
  }

  &.cdk-focused .mat-checkbox-frame {
    border-color: $ui-primary !important;
  }
}
