@import "colors";
// Ag has three sets of icon fonts available.
// None that match mockup style for checkboxes.

.ag-theme-alpine {
  font-family: "Inter", sans-serif;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;

  .ag-root-wrapper {
    border: none;
  }
  .ag-header-container {
    height: 32px !important;
    min-height: 32px !important;
    background: $white !important;
  }

  .ag-header-row {
    height: 32px !important;
  }

  .ag-row-odd {
    background-color: $pure-white !important;
  }

  .ag-row-selected::before {
    background-color: $pure-white !important;
    background-image: none;
  }

  .ag-row-hover::before,
  .ag-row-hover.ag-row-selected::before {
    background-color: $light-gray !important;
    background-image: none;
  }

  .ag-row {
    border: none !important;
  }

  .ag-row-group {
    height: 36px !important;
  }

  .ag-cell-value {
    p {
      height: 36px;
      margin-bottom: 0px !important;
      line-height: 36px;
    }
  }

  .ag-cell-value:nth-child(2) {
    p {
      font-size: 12px;
      font-weight: 600;
      line-height: 1.33;
      color: $black;
    }
  }

  .ag-cell {
    padding-right: 0 !important;
  }

  .ag-row .ag-cell:last-child {
    padding-left: 0 !important;
  }

  .ag-cell-wrapper {
    height: 36px;
  }

  .ag-header-cell-text {
    font-size: 11px;
    font-weight: 500;
    line-height: 1.09;
    color: $greyish-brown;
  }

  .ag-group-contracted {
    transform: rotate(90deg);
  }

  .ag-group-expanded,
  .ag-group-contracted {
    margin-top: -2px;
    margin-left: -4px;
  }

  .ag-row-focus {
    background-color: rgba($gray-blue, 0.35) !important;
  }

  .ag-icon {
    font-size: 22px;
  }

  .ag-row .ag-cell {
    font-size: 12px;
  }

  .ag-row .ag-cell:nth-child(2) {
    font-weight: 500;
  }

  .ag-row {
    .ag-cell-wrapper:nth-child(1),
    fsx-btn-cell-renderer,
    .ag-cell-wrapper:nth-child(7) {
      opacity: 0;
    }

    &:hover,
    &.ag-row-focus {
      .ag-cell-wrapper:nth-child(1),
      fsx-btn-cell-renderer,
      .ag-cell-wrapper:nth-child(7) {
        opacity: 1;
      }
    }

    &.ag-row-selected .ag-cell-wrapper:nth-child(1) {
      opacity: 1;
    }
  }

  .ag-checkbox-input {
    cursor: pointer;
    outline: none;
  }

  .ag-checkbox-input-wrapper:focus-within,
  .ag-checkbox-input-wrapper:focus {
    outline: none;
    box-shadow: none;
  }

  .ag-sort-indicator-icon {
    padding-left: 0 !important;
  }

  .ag-loading {
    display: none;
  }

  .ag-body-horizontal-scroll {
    display: none !important;
  }

  .ag-header-cell:focus::after {
    border: none !important;
  }

  .ag-header-active:focus .ag-header-cell-label {
    border: 1px solid $ui-primary !important;
  }
}
