/**
* Generated theme by Material Theme Generator
* https://materialtheme.arcsine.dev
* Fork at: https://materialtheme.arcsine.dev/?c=YHBhbGV0dGU$YHByaW1hcnk$YF48IzE5OTFlYiIsIj9lcjwjYmFkZWY5IiwiO2VyPCMwZTc0ZTJ$LCIlPmBePCNiMTUxMzIiLCI~ZXI8I2U4Y2JjMiIsIjtlcjwjOTgzNzFmfiwid2Fybj5gXjwjZDEyZjEwIiwiP2VyPCNmMWMxYjciLCI7ZXI8I2MwMWQwOX4sIj9UZXh0PCMzNTNjNDgiLCI~PTwjZmZmZmZmIiwiO1RleHQ8I2ZmZmZmZiIsIjs9PCMyYzJjMmN$LCJmb250cz5bYEA8KC00IiwiZmFtaWx5PEludGVyfixgQDwoLTN$LGBAPCgtMn4sYEA8KC0xfixgQDxoZWFkbGluZX4sYEA8dGl0bGV$LGBAPHN1YiktMn4sYEA8c3ViKS0xfixgQDxib2R5LTJ$LGBAPGJvZHktMSIsImZhbWlseTxJbnRlciIsImxpbmVIZWlnaHQ$MTgsInNpemU$MTN9LGBAPGJ1dHRvbn4sYEA8Y2FwdGlvbn4sYEA8aW5wdXQiLCJmYW1pbHk8SW50ZXIiLCJsaW5lSGVpZ2h0PjE4LCJzaXplPjEzLCJzcGFjaW5nPm51bGx9XSwiaWNvbnM8U2hhcnAiLCI~bmVzcz50cnVlLCJ2ZXJzaW9uPjEyfQ==
*/
@use "@angular/material" as mat;

@import "colors";

// Light Theme Text
$light-text: $light-gray;
$light-primary-text: $light-text;
$dark-text: $default-font-color;
$dark-primary-text: rgba($dark-text, 0.87);
$dark-accent-text: rgba($dark-primary-text, 0.54);
$dark-disabled-text: rgba($dark-primary-text, 0.38);
$dark-dividers: rgba($dark-primary-text, 0.12);
$dark-focused: rgba($dark-primary-text, 0.12);

$mat-light-theme-foreground: (
  base: black,
  divider: $dark-dividers,
  dividers: $dark-dividers,
  disabled: $dark-disabled-text,
  disabled-button: rgba($dark-text, 0.26),
  disabled-text: $dark-disabled-text,
  elevation: black,
  secondary-text: $dark-accent-text,
  hint-text: $dark-disabled-text,
  accent-text: $dark-accent-text,
  icon: $dark-accent-text,
  icons: $dark-accent-text,
  text: $dark-primary-text,
  slider-min: $dark-primary-text,
  slider-off: rgba($dark-text, 0.26),
  slider-off-active: $dark-disabled-text,
);

$light-background: $light-gray;
$light-bg-darker-5: darken($light-background, 5%);
$light-bg-darker-10: darken($light-background, 10%);
$light-bg-darker-20: darken($light-background, 20%);
$light-bg-darker-30: darken($light-background, 30%);
$light-bg-lighter-5: lighten($light-background, 5%);
$dark-bg-tooltip: lighten($text-darker-active, 20%);
$dark-bg-alpha-4: rgba($text-darker-active, 0.04);
$dark-bg-alpha-12: rgba($text-darker-active, 0.12);

$mat-light-theme-background: (
  background: $light-background,
  status-bar: $light-bg-darker-20,
  app-bar: $light-bg-darker-5,
  hover: $dark-bg-alpha-4,
  card: $light-bg-lighter-5,
  dialog: $light-bg-lighter-5,
  tooltip: $dark-bg-tooltip,
  disabled-button: $dark-bg-alpha-12,
  raised-button: $light-bg-lighter-5,
  focused-button: $dark-focused,
  selected-button: $light-bg-darker-20,
  selected-disabled-button: $light-bg-darker-30,
  disabled-button-toggle: $light-bg-darker-10,
  unselected-chip: $light-bg-darker-10,
  disabled-list-option: $light-bg-darker-10,
);

body {
  --primary-color: $ui-primary;
  --primary-lighter-color: $ui-primary-lighter;
  --primary-darker-color: $ui-primary-darker;
  --text-primary-color: #{$light-primary-text};
  --text-primary-lighter-color: #{$dark-primary-text};
  --text-primary-darker-color: #{$light-primary-text};
}
$mat-primary: (
  main: $ui-primary,
  lighter: $ui-primary-lighter,
  darker: $ui-primary-darker,
  200: $ui-primary,
  // For slide toggle,
  contrast:
    (
      main: $light-primary-text,
      lighter: $dark-primary-text,
      darker: $light-primary-text,
    ),
);
$theme-primary: mat.define-palette($mat-primary, main, lighter, darker);

body {
  --accent-color: $caramel;
  --accent-lighter-color: $caramel-lighter;
  --accent-darker-color: $caramel-darker;
  --text-accent-color: #{$light-primary-text};
  --text-accent-lighter-color: #{$dark-primary-text};
  --text-accent-darker-color: #{$light-primary-text};
}
$mat-accent: (
  main: $caramel,
  lighter: $caramel-lighter,
  darker: $caramel-darker,
  200: $caramel,
  contrast: (
    main: $light-primary-text,
    lighter: $dark-primary-text,
    darker: $light-primary-text,
  ),
);
$theme-accent: mat.define-palette($mat-accent, main, lighter, darker);
