@import "colors";

input {
  width: 100%;
  height: 24px;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: "Inter", sans-serif;
  box-sizing: border-box;
  border: solid 1px $border-gray;
  font-size: 12px;
  background-color: $pure-white;
  color: $dark;

  &::placeholder {
    font-size: 12px;
    line-height: 1.5;
    color: $placeholder-gray;
    letter-spacing: normal;
  }

  &:disabled {
    background: $disabled-gray;
    color: $placeholder-gray;
  }

  &:focus {
    border: solid 1px $secondary-blue;
    outline: none;
  }
}

mat-form-field {
  width: 100%;

  .mat-form-field-flex {
    padding: 0 !important;
  }

  .mat-form-field-infix {
    padding: 0 !important;
    border: 0 !important;
  }
}
