@import "../../../../shared/src/lib/styles/colors.scss";

// Inputs
$input-placeholder: $placeholder-gray;
$input-border: $cloudy-blue;
$input-focus-border: $ui-primary;
$input-disabled-background: $disabled-gray;
$text-color: $charcoal-gray;
$disabled-text: $lighter-gray;

// Labels
$label-color: $lighter-gray;
$label-icon-hover: $darker-gray;
$disabled-label: $lighter-gray;

// Tooltips
$text-default: #5d6a7e;
$light-gray: #ffffff;
$tooltip-border: #bcbcc5;
$light-gray: $pure-white;
$tooltip-border: $lighter-gray;

// General
$input-error: $brick-orange;
$hint-color: $lighter-gray;
$text-dark: $dark;

// Checkbox
$checkbox-background: $ui-primary;
$checkbox-disabled-background: $cloudy-blue;
$checkbox-check-mark: $lighter-gray;
$checkbox-label: $dark;
$checkbox-label-icon-hover: $label-icon-hover;

// Date
$date-picker-primary-color: $ui-primary;
$date-picker-hover-color: rgba(25, 145, 235, 0.2);
$date-picker-today: $disabled-gray;
$date-picker-divider: $light-lilac;

// dropdowns
$select-hover: $light-gray;
$select-panel-border: $ui-primary-link;
$select-option-text-color: $charcoal-gray;

// address
$icon: $ui-primary;

// button
$button-background-primary: $ui-primary;
$button-background-secondary: $light-background;
$button-foreground-primary: $pure-white;
$button-foreground-secondary: $text-default;

// grids
$grid-hover-background: #f5f9fe;
$grid-header-background: #f4f9ff;

// tabs
$secondary-tab-color: $secondary-blue;
