// FSX Color Palette
$black: #000000;
$white: #ffffff;
$light-gray: #f7f7f7;
$pure-white: #ffffff;
$black: #000000;
$greyish-brown: #555;
$dark: #1d2a39;
$charcoal-gray: #353c48;
$brown-grey: #949494;
$cloudy-blue: #bcbcc5;
$primary-blue: #4766ff;
$secondary-blue: #3b4cec;
$dark-blue: #254bb6;
$periwinkle: #7b91ff;
$gray-blue: #d9e0ff;
$pale-lilac: #e1e3ee;
$light-gray: #f7f7f7;
$light-lilac: #ededed;
$lighter-lilac: #ededed14;
$brick-orange: #d12f10;
$aquamarine: #00d3ac;
$dusty-orange: #ff8427;
$slate: #5d6a7e;
$teal: #00a889;
$grey-blue: rgba(217, 224, 255, 0.35);

// TODO: Refactor everything below this, Colors used across the project (names will change)
$document-upload-placeholder: rgba($gray-blue, 0.35);
$default-background: #d9d9d9;
$light-background: $light-gray;
$medium-background: #eaeaea;
$default-font-color: $charcoal-gray;
$text-default: $slate;
$text-darker-active: $dark;
$ui-primary: $primary-blue;
$ui-primary-lighter-still: #f5f9fe;
$ui-primary-lighter: #badef9;
$ui-primary-darker: #0e74e2;
$ui-primary-dark: #0072ce;
$ui-primary-link: #2680eb;
$stone-medium: #7a889a;
$caramel: #b15132;
$caramel-lightest: #f9ede9;
$caramel-lighter: #e8cbc2;
$caramel-darker: #98371f;
$snow-light: #f6f6f6;
$darker-gray: #2f445b;
$lighter-gray: #657080;
$border-gray: #bcbcc5;
$placeholder-gray: #919191;
$disabled-gray: #f4f4f4;
$cancel-gray: #949494;
$error: #d04119;
$error-lighter: #fde7e3;
$error-darker: #c01d09;
$card-border: $light-lilac;
$dark-primary-text: $charcoal-gray;
$ui-tertiary: #f2f2f2;
$status-gray: #b4b4b4;
$status-partial: #afbcff;
$status-cancelled: #ffc600;
$status-cancelled-darker: #ddac00;
$divider-shadow: #dddddd55;
