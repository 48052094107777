.grid-container {
  position: relative;

  .header-row {
    font-size: 12px;
    font-weight: bold;
    height: 30px;
    line-height: 30px;
    vertical-align: middle;
    border-bottom: 1px solid #f7f7f7;
    background: $pure-white;
    position: sticky;
    top: 0;
    z-index: 999;
  }

  .content-row {
    background: $pure-white;
    z-index: 1;
  }

  .data-row {
    background: $pure-white;
    line-height: 50px;
    vertical-align: middle;
    height: 50px;
    cursor: pointer;

    &:hover {
      background: $light-background;

      .inner-data-row {
        .hover-icons {
          visibility: visible;
        }
      }
    }
  }

  .detail-row {
    background: $light-background;
    padding: 15px 15px 15px 45px;
  }

  .add-new-row {
    font-weight: bold;
    font-size: 12px;

    .add-icon {
      padding: 0 5px 0 0;
      font-size: 18px;
    }

    .add-new-row-inner {
      height: 45px;
      margin: 5px 15px 5px 15px;
      padding: 0 0 0 27px;
      background-color: transparent;
      cursor: pointer;
      border: 1px dashed transparent;
      color: $ui-primary;

      &:hover,
      &:focus {
        border: 1px dashed $ui-primary;
        background: $grid-hover-background;
        border-radius: 0;
      }
    }

    .max-allowed-message {
      height: 45px;
      margin: 5px 15px 5px 15px;
      padding: 0 0 0 30px;
      font-weight: normal;
    }
  }

  .checkbox-col {
    text-align: center;
    width: 45px;
  }
}

.inner-data-row {
  display: inline-block;
  height: 50px;

  .title-text {
    display: block;
    height: 15px;
    line-height: 1.33;
    font-weight: bold;
    font-size: 12px;
  }

  .title-subtext {
    display: block;
    line-height: 15px;
    font-size: 10px;
    color: $greyish-brown;
  }

  #party-type {
    margin-right: 30px;
  }

  .hover-icons {
    margin-right: 10px;
    visibility: hidden;

    span {
      margin-left: 20px;

      &:hover {
        color: $ui-primary;
      }
    }
  }
}

.filterable-row.filtered {
  display: none;
}
