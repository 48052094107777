@import "colors";
.cdk-global-scrollblock {
  overflow-y: hidden !important;
}

mat-progress-bar {
  position: absolute !important;

  &.submit-progress-bar {
    color: $primary-blue;
    width: calc(100% - 30px);
  }
}

.mat-bottom-sheet {
  height: 174px;
  min-width: calc(
    100vw - 90px
  ); // account for left nav bar and padding in between container
  margin-left: 60px; // To alignt with inner container
  margin-bottom: 15px !important;
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  color: var($black);

  .mat-bottom-sheet-container {
    min-width: inherit;
    max-width: inherit;
  }

  .mat-bottom-sheet-container {
    padding: 0;
  }
}

.mat-date-range-input-container {
  display: flex;
  flex-direction: row;
  align-items: normal !important;
  justify-content: space-evenly !important;

  .mat-date-range-input-separator {
    margin-top: 5px;
  }

  input {
    border: none;
  }
}
