@import "colors";

p {
  display: flex;
  align-items: center;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  font-weight: 500;
  color: $dark;
  font-size: 12px;
}

.actionable-text {
  height: 15px;
  font-size: 12px;
  line-height: 1.5;
  color: $secondary-blue;
}

.placeholder-text {
  height: 15px;
  font-size: 12px;
  line-height: 1.17;
  color: $placeholder-gray;
}
