@import "colors";

label {
  display: flex;
  align-items: center;
  box-sizing: content-box;
  user-select: none;
  letter-spacing: normal;
  font-stretch: normal;
  font-style: normal;
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
  font-size: 10px;
  line-height: 1.8;
  font-weight: 600;
}

.input-label {
  height: 14px;
  color: $greyish-brown;
  padding-left: 6px;
}

.dropdown-options-label {
  height: 12px;
  color: $darker-gray;
  padding: 6px 8px 4px 8px;
}
