@import "colors";

::ng-deep .mat-autocomplete-panel {
  box-shadow: none;
  border-radius: 0 !important;
  border: 1px solid $ui-primary-link;
  margin-top: 3px;
  letter-spacing: normal;

  .mat-option.mat-active {
    background-color: $light-gray;
  }
}

.dropdown-option-primary-text,
.dropdown-option-secondary-text {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: left;
}

.dropdown-option-primary-text {
  font-size: 12px;
  line-height: 1.33;
  color: $black;
}

.dropdown-option-secondary-text {
  font-size: 10px;
  font-weight: 500;
  line-height: 1.2;
  color: $greyish-brown;
}
