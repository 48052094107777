@import "fsx-colors";

button {
  height: 32px;
  padding: 0 15px;
  font-size: 12px;
  font-weight: bold;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  user-select: none;

  &.primary {
    background-color: $button-background-primary;
    color: $button-foreground-primary;

    &.disabled {
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
    }
    &:hover {
      background-color: $secondary-blue;
    }
  }

  &.secondary {
    background-color: $button-background-secondary;
    color: $button-foreground-secondary;

    &.disabled {
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
    }

    &.blue {
      color: $ui-primary;
    }
  }

  &.tertiary {
    color: $ui-primary;
    background-color: transparent;
    border: 1px solid $ui-primary;
    &:hover {
      box-shadow: 0 1px 6px 0 rgba(0, 0, 0, 0.05);
      border: solid 1px $dark-blue;
      color: $dark-blue;
    }
  }

  &.icon-button {
    background-color: transparent;
    border: none;
    width: auto;
    height: 24px;
    padding: 0;

    &.disabled {
      cursor: default;
      opacity: 0.6;
      pointer-events: none;
    }
  }

  &.transparent {
    background-color: transparent;
  }

  &.text-button {
    padding: 0px;
    height: auto;
    width: auto;
  }

  &.link-button {
    padding: 10px;
    height: auto;
    width: auto;
  }

  .form-delete-button {
    height: 100%;
    display: block;
    background-image: url(../assets/delete_default.svg);
    background-repeat: no-repeat;
    background-size: 16px;
    background-position: center;
    margin-left: 0;
    margin-top: -5px;

    &:hover {
      background-image: url(../assets/delete_hover.svg);
    }
  }

  &.disabled .form-delete-button {
    background-image: url(../assets/delete_disabled.svg) !important;
  }
}
